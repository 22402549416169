/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  checkIfDate,
  extractTokens,
  NOCODE_DEFAULT_VALUE,
} from "../utils/utils";
import JSONAccordion from "./sub-components/accordian";
import { RxCross2 } from "react-icons/rx";
import DataListInput, { useComboboxControls } from "react-datalist-input";
import "react-datalist-input/dist/styles.css";
import { AutoSuggestions } from "./sub-components/json-editor/autoSuggestions";
import AddNewData from "./sub-components/json-editor/addKeyButton";

export const NectedJSONEditor = ({
  parentKey = "",
  parentJSON,
  originalPayload,
  nectedSuggestions,
  onChange,
  from = "parent",
  selectedRow = null,
  setSelectedRow,
  globalTokenMap,
  editorMeta=null
}) => {
  const [jsonObj, setJson] = useState(parentJSON);
  const [, setIsJSONValid] = useState(false);
  const { setValue } = useComboboxControls({ isExpanded: false });

  useEffect(() => {
    setJson(parentJSON);
    try {
      let a = JSON.stringify(parentJSON);
      JSON.parse(a);
      setIsJSONValid(true);
    } catch (error) {
      setIsJSONValid(false);
    }
  }, [parentJSON]);

  /*
   * Helper methods block for json editor
   *
   */
  const accessObject = (keys) => {
    let result = { ...originalPayload };
    for (let key of keys) {
      if (result && typeof result === "object") {
        result = result[key];
      } else {
        return undefined;
      }
    }
    return result;
  };

  const isParentArray = (keys) => {
    let parentKeys = [
      ...keys.split("##").splice(0, keys.split("##").length - 1),
    ];
    if (keys.length === 1) {
      return false;
    }

    if (Array.isArray(accessObject(parentKeys))) {
      return true;
    }
  };

  const getDefaultState = (parentKey, payload) => {
    if (parentKey === "" && typeof payload === "object") {
      return true;
    }

    if (payload !== null) {
      if (Array.isArray(payload)) {
        return payload.length === 0;
      }

      if (!Array.isArray(payload) && typeof payload === "object") {
        return Object.keys(payload).length === 0;
      }
    }

    if (selectedRow === parentKey) {
      return true;
    }

    return false;
  };

  const detectTypeNoCode = (value) => {
    if (value === null || value === "null") {
      return "null";
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return "array";
      }
      return "object";
    }

    let tokens = extractTokens(value.toString());
    if (tokens.length > 0) {
      let tokenValue = nectedSuggestions.find((j) => j.value === value.trim());

      if (tokenValue === undefined && globalTokenMap !== undefined) {
        tokenValue = globalTokenMap[value.trim()];
      }

      if (tokenValue) {
        return tokenValue.meta === "numeric"
          ? "number"
          : tokenValue.meta.toLocaleLowerCase() === "date" ||
            tokenValue.meta.toLocaleLowerCase() === "datetime"
          ? "string"
          : tokenValue.meta.toLocaleLowerCase();
      } else {
        return "string";
      }
    }

    if (typeof value === "string") {
      return "string";
    }

    return typeof value;
  };

  const detectTypeNoCodeForRender = (value) => {
    if (value === null || value === "null") {
      return "null";
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return "array";
      }
      return "object";
    }

    let tokens = extractTokens(value.toString());
    if (tokens.length > 0) {
      let tokenValue = nectedSuggestions.find((j) => j.value === value.trim());

      if (tokenValue === undefined && globalTokenMap !== undefined) {
        tokenValue = globalTokenMap[value.trim()];
      }

      if (tokenValue) {
        return tokenValue.meta === "numeric"
          ? "number"
          : tokenValue.meta.toLocaleLowerCase() === "date" ||
            tokenValue.meta.toLocaleLowerCase() === "datetime"
          ? "string"
          : tokenValue.meta.toLocaleLowerCase();
      } else {
        return "string";
      }
    }

    if (typeof value === "string") {
      const date = checkIfDate(value);
      return date.type;
    }

    return typeof value;
  };

  const getDataListItems = (value, dType = "") => {
    const dataType = dType !== "" ? dType : (detectTypeNoCode(value) === "number" ? "numeric" : detectTypeNoCode(value));
    const filteredSuggestions = nectedSuggestions.filter(
      (i) => i.meta === dataType
    );
    filteredSuggestions.map((i) => {
      i.value = i.value.replaceAll('"', "");
      i.key = i.value;
      return i;
    });

    return filteredSuggestions;
  };

  const convertValuesToSupportedFormat = (value) => {
    if (value === null || value === "null") {
      return "null";
    }

    let parsedDateObj = checkIfDate(value);
    if (parsedDateObj) {
      if (parsedDateObj.type === "date") {
        return value;
        // return dateFormat(new Date(value), "yyyy-mm-dd");
      } else if (parsedDateObj.type === "datetime-local") {
        return value;
        // return dateFormat(new Date(value), "yyyy-mm-dd'T'HH:MM:ss");
      }
    }

    return value;
  };

  const renderKeyLength = (type, key) => {
    if (type === "array") {
      return key.length;
    } else if (type === "object" && key !== null) {
      return Object.keys(key).length;
    } else if (key === null) {
      return 0;
    }

    return key.length;
  };

  const checkType = (type, value, globalTokenMap) => {
    const dataType = type === "number" ? "numeric" : type;
    const tokens = extractTokens(value.toString());
    if (tokens.length > 0) {
      let tokenData = nectedSuggestions.filter(
        (i) => i.value.replaceAll('"', "") === tokens[0]
      );
      if (tokenData.length > 0) {
        return (tokenData[0].meta === "date" || tokenData[0].meta === "dateTime" ? 'string' : tokenData[0].meta.toLocaleLowerCase()) === dataType.toLocaleLowerCase();
      } else {
        tokenData = globalTokenMap[tokens[0]];
        if (tokenData) {
          return (tokenData.meta === "date" || tokenData.meta === "dateTime" ? 'string' : tokenData.meta.toLocaleLowerCase()) === dataType.toLocaleLowerCase();
        } else {
          return true;
        }
      }
    }

    switch (type) {
      case "date":
        // return true;
        return Object.prototype.toString.call(value) === "[object Date]";
      case "datetime-local":
        // return true;
        return !isNaN(Date.parse(value));
      case "boolean":
        return typeof value === "boolean";
      case "number":
        return typeof value === "number";
      case "string":
        return typeof value === "string";
      case "":
        return true;
      default:
        return false;
    }
  };

  const convertToDataType = (value, dataType, format = false) => {
    let tokens = extractTokens(value);
    if (tokens.length > 0) {
      return value;
    }

    switch (dataType) {
      case "date":
        return value;
      // return format ? dateFormat(new Date(value), "yyyy-mm-dd") : new Date(value);
      case "datetime-local":
        return value;
      // return format ? dateFormat(new Date(value), "yyyy-mm-dd'T'HH:MM:ss") : new Date(value);
      case "boolean":
        return JSON.parse(value);
      case "number":
        return !isNaN(value) ? Number(value) : 0;
      case "string":
        return String(value);
      default:
        return value;
    }
  };

  /*
   * End of Helper methods block for json editor
   *
   */

  const updateValue = (obj, keys, value) => {
    const updatedObj = structuredClone(obj);
    let current = updatedObj;

    const lastKeyIndex = keys.length - 1;
    for (let i = 0; i < lastKeyIndex; i++) {
      const key = keys[i];
      const nextKey = keys[i + 1];

      if (key === "") {
        // If the key is empty and we're at the root level, set current as the root node
        if (i === 0) {
          current = updatedObj;
        } else {
          throw new Error("Empty key encountered. Cannot update.");
        }
      } else {
        if (Array.isArray(current)) {
          const index = parseInt(key, 10);
          if (isNaN(index)) {
            throw new Error(`Invalid array index: ${key}`);
          }
          if (!current[index]) {
            // Only create a new array element if the current index is undefined
            current[index] =
              typeof nextKey === "number" || nextKey.match(/^\d+$/) ? [] : {};
          }
          current = current[index];
        } else if (typeof current === "object" && current !== null) {
          if (!current[key]) {
            current[key] =
              typeof nextKey === "number" || nextKey.match(/^\d+$/) ? [] : {};
          }
          current = current[key];
        } else {
          throw new Error(`Unexpected structure at key: ${key}`);
        }
      }
    }

    const lastKey = keys[lastKeyIndex];
    if (lastKey === "") {
      // If the last key is empty, set current as the root node
      current = updatedObj;
    } else {
      if (Array.isArray(current)) {
        const index = parseInt(lastKey, 10);
        if (isNaN(index)) {
          throw new Error(`Invalid array index: ${lastKey}`);
        }
        current[index] = value;
      } else if (typeof current === "object" && current !== null) {
        current[lastKey] = value;
      } else {
        throw new Error(`Unexpected structure at key: ${lastKey}`);
      }
    }

    return updatedObj;
  };

  const handleAddNewValue = (dataType, key) => {
    if (Array.isArray(originalPayload)) {
      handleAddNewValueToArray(dataType, key);
    } else {
      handleAddNewValueToObj(dataType, key);
    }
  };

  const handleAddNewValueToObj = (dataType, key) => {
    let oPayload = structuredClone(originalPayload);
    let currentObj = oPayload;
    let value = NOCODE_DEFAULT_VALUE[dataType];
    let keys = key.split("##");

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const nextKey = keys[i + 1];

      if (i === keys.length - 1) {
        // If we're at the last key, we insert the value
        if (Array.isArray(currentObj)) {
          const index = parseInt(key);
          if (
            typeof currentObj[index] === "object" &&
            currentObj[index] !== null &&
            !Array.isArray(currentObj[index])
          ) {
            const newKeyName = `key_name_${
              Object.keys(currentObj[index]).length + 1
            }`;
            currentObj[index][newKeyName] = value;
          } else if (Array.isArray(currentObj[index])) {
            currentObj[index].push(value);
          } else {
            currentObj[index] = value;
          }
        } else if (typeof currentObj === "object" && currentObj !== null) {
          if (key === "") {
            const newKeyName = `key_name_${Object.keys(currentObj).length + 1}`;
            currentObj[newKeyName] = value;
          } else {
            if (Array.isArray(currentObj[key])) {
              currentObj[key].push(value);
            } else if (
              typeof currentObj[key] === "object" &&
              currentObj[key] !== null
            ) {
              const newKeyName = `key_name_${
                Object.keys(currentObj[key]).length + 1
              }`;
              currentObj[key][newKeyName] = value;
            } else {
              currentObj[key] = value;
            }
          }
        }
      } else {
        // If we're not at the last key, we navigate the structure
        if (Array.isArray(currentObj)) {
          const index = parseInt(key);
          if (!currentObj[index]) {
            currentObj[index] =
              nextKey && (typeof nextKey === "number" || nextKey.match(/^\d+$/))
                ? []
                : {};
          }
          currentObj = currentObj[index];
        } else if (typeof currentObj === "object" && currentObj !== null) {
          if (!currentObj[key]) {
            currentObj[key] =
              nextKey && (typeof nextKey === "number" || nextKey.match(/^\d+$/))
                ? []
                : {};
          }
          currentObj = currentObj[key];
        }
      }
    }

    onChange(oPayload);
  };

  const handleAddNewValueToArray = (dataType, key) => {
    let oPayload = structuredClone(originalPayload);
    let current = oPayload;
    let value = NOCODE_DEFAULT_VALUE[dataType];

    const keys = key.split("##");

    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];
      const nextKey = keys[i + 1];

      if (i === keys.length - 1) {
        // We are at the last key
        if (Array.isArray(current)) {
          const index =
            currentKey === "" ? current.length : parseInt(currentKey);
          if (!isNaN(index)) {
            if (!current[index]) {
              current[index] = value;
            } else if (Array.isArray(current[index])) {
              current[index].push(value);
            } else if (typeof current[index] === "object") {
              const objKeys = Object.keys(current[index]);
              const arrayKey = objKeys.find((k) =>
                Array.isArray(current[index][k])
              );
              if (arrayKey) {
                current[index][arrayKey].push(value);
              } else {
                current[index][`key_name_${objKeys.length + 1}`] = value;
              }
            } else {
              current[index] = value;
            }
          } else {
            current.push(value);
          }
        } else if (typeof current === "object") {
          if (current[currentKey] === undefined) {
            current[currentKey] = value;
          } else if (Array.isArray(current[currentKey])) {
            current[currentKey].push(value);
          } else if (typeof current[currentKey] === "object") {
            current[currentKey][
              `key_name_${Object.keys(current[currentKey]).length + 1}`
            ] = value;
          } else {
            current[currentKey] = value;
          }
        }
      } else {
        // We are not at the last key, so navigate deeper
        if (Array.isArray(current)) {
          const index = parseInt(currentKey);
          if (!isNaN(index)) {
            if (!current[index]) {
              current[index] =
                typeof nextKey === "number" || nextKey.match(/^\d+$/) ? [] : {};
            }
            current = current[index];
          } else {
            throw new Error("Invalid array index");
          }
        } else if (typeof current === "object") {
          if (!current[currentKey]) {
            current[currentKey] =
              typeof nextKey === "number" || nextKey.match(/^\d+$/) ? [] : {};
          }
          current = current[currentKey];
        }
      }
    }

    onChange(oPayload);
  };

  const handleRemove = (keyToRemove, parentKey, index) => {
    const updatedJson = Array.isArray(originalPayload)
      ? [...originalPayload]
      : { ...originalPayload };
    const keys = parentKey.split("##").filter((key) => key !== ""); // Remove empty keys
    let current = updatedJson;
    let parent = null; // To keep track of the parent
    let parentKeyIndex = null; // To keep track of the key/index in the parent
    if (
      typeof current === "object" &&
      !Array.isArray(current) &&
      keys.length === 0
    ) {
      // this is the root node in case of updateJSON === object .
      delete current[keyToRemove];
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (current[key] === undefined) return; // Key does not exist

      // Keep track of the parent and the key/index in the parent
      parent = current;
      parentKeyIndex = key;

      if (i === keys.length - 1) {
        // Last level
        if (Array.isArray(current[key])) {
          // If the current level is an array, remove the element at the specified index
          current[key].splice(index, 1);
          // Check if the parent array becomes empty and delete the parent key
          if (current[key].length === 0) {
            if (Array.isArray(parent)) {
              parent.splice(parentKeyIndex, 1);
            } else {
              delete parent[parentKeyIndex];
            }
            console.log(
              "Parent array became empty. Deleted parent key:",
              parentKeyIndex
            );
          }
        } else if (typeof current[key] === "object" && current[key] !== null) {
          // If the current level is an object, delete the specified key
          delete current[key][keyToRemove];
          // Check if the parent object becomes empty and delete the parent key
          if (current[key] !== null && Object.keys(current[key]).length === 0) {
            if (Array.isArray(parent)) {
              parent.splice(parentKeyIndex, 1);
            } else {
              delete parent[parentKeyIndex];
            }
            console.log(
              "Parent object became empty. Deleted parent key:",
              parentKeyIndex
            );
          }
        } else {
          // For other data types, remove the element at the specified index
          current.splice(index, 1);
          // Check if the parent array becomes empty and delete the parent key
          if (current.length === 0) {
            if (keys.length > 1) {
              // const parentKeys = keys.slice(0, -2);
              // const grandParent = parentKeys.reduce((obj, k) => obj[k], updatedJson);
              // if (Array.isArray(grandParent)) {
              //     grandParent.splice(parentKeys[parentKeys.length - 1], 1);
              // } else {
              //     delete grandParent[parentKeys[parentKeys.length - 1]];
              // }
            } else {
              updatedJson.splice(parentKeyIndex, 1); // Corrected from delete to splice for array
            }
            console.log(
              "Parent array became empty. Deleted parent key:",
              parentKeyIndex
            );
          }
        }
      } else {
        // Move to the next level
        current = current[key];
      }
    }

    onChange(updatedJson);
  };

  const updateFieldValue = (type, parentKey, updatedValue) => {
    if (
      checkType(type, convertToDataType(updatedValue, type), globalTokenMap)
    ) {
      const jsonData = structuredClone(originalPayload);

      const response = updateValue(
        jsonData,
        parentKey,
        convertToDataType(updatedValue, type, false)
      );

      onChange(response);
    } else {
      if (type !== "null" && type !== null) {
        alert(`Please enter the value of type : ${type}`);
      }
    }
  };

  const handleUpdateKeyNameForArray = (oldKey, newKey, parentKey) => {
    const updateKeyNameInObject = (obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (key === oldKey) {
          newObj[newKey] = obj[key];
        } else {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };

    const traverseAndUpdate = (data, parentKeys = []) => {
      if (Array.isArray(data)) {
        return data.map((item, index) => {
          return traverseAndUpdate(item, [...parentKeys, index]);
        });
      } else if (typeof data === "object" && data !== null) {
        if (parentKeys.join("##") === parentKey) {
          return updateKeyNameInObject(data);
        }
        const newData = {};
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key]) || typeof data[key] === "object") {
            newData[key] = traverseAndUpdate(data[key], [...parentKeys, key]);
          } else {
            newData[key] = data[key];
          }
        });
        return newData;
      } else {
        return data;
      }
    };

    const updatedPayload = traverseAndUpdate(originalPayload);

    onChange(updatedPayload);
  };

  const handleUpdateKeyName = (oldKey, newKey, parentKey) => {
    if (Array.isArray(originalPayload)) {
      handleUpdateKeyNameForArray(oldKey, newKey, parentKey);
    } else {
      handleUpdateKeyNameForObject(oldKey, newKey, parentKey);
    }
  };

  const handleUpdateKeyNameForObject = (oldKey, newKey, parentKey) => {
    if (!parentKey) {
      if (originalPayload.hasOwnProperty(oldKey)) {
        const newPayload = {};
        Object.keys(originalPayload).forEach((key) => {
          if (key === oldKey) {
            newPayload[newKey] = originalPayload[oldKey];
          } else {
            newPayload[key] = originalPayload[key];
          }
        });
        onChange(newPayload);
      } else {
        onChange(originalPayload);
      }
      return;
    }

    const keys = parentKey.split("##");
    let updatedPayload = { ...originalPayload };

    let current = updatedPayload;
    for (let i = 0; i < keys.length - 1; i++) {
      current = current[keys[i]];
    }

    const keyToUpdate = keys[keys.length - 1];

    if (Array.isArray(current[keyToUpdate])) {
      const index = current[keyToUpdate].findIndex(
        (item) => item && item.hasOwnProperty(oldKey)
      );
      if (index !== -1) {
        const item = current[keyToUpdate][index];
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (key === oldKey) {
            newItem[newKey] = item[key];
          } else {
            newItem[key] = item[key];
          }
        });
        current[keyToUpdate][index] = newItem;
      }
    } else if (
      current[keyToUpdate] &&
      current[keyToUpdate].hasOwnProperty(oldKey)
    ) {
      const newItem = {};
      Object.keys(current[keyToUpdate]).forEach((key) => {
        if (key === oldKey) {
          newItem[newKey] = current[keyToUpdate][key];
        } else {
          newItem[key] = current[keyToUpdate][key];
        }
      });
      current[keyToUpdate] = newItem;
    }

    onChange(updatedPayload);
  };

  const renderFields = (
    value,
    updateFieldValue,
    parentKey,
    nectedSuggestions
  ) => {
    if (detectTypeNoCodeForRender(value) === "boolean") {
      return (
        <>
          <select
            value={convertValuesToSupportedFormat(value)}
            onChange={(e) => {
              updateFieldValue(
                detectTypeNoCode(value),
                parentKey,
                e.target.value
              );
            }}
          >
            <option value="true">True</option>
            <option value="false">False</option>
            {nectedSuggestions
              .filter((i) => i.meta === "boolean")
              .map((i, index) => {
                return <option value={i.value}>{i.value}</option>;
              })}
          </select>
        </>
      );
    } else if (detectTypeNoCodeForRender(value) === "string") {
      return (
        <>
          <AutoSuggestions
            suggestions={[
              ...getDataListItems("string"),
              ...getDataListItems("", "date"),
              ...getDataListItems("", "dateTime"),
            ]}
            onChange={(value) => {
              let updatedValue = value;
              if (updateValue === "") {
                updatedValue = NOCODE_DEFAULT_VALUE[detectTypeNoCode(value)];
              }
              updateFieldValue(
                detectTypeNoCode(value),
                parentKey,
                updatedValue
              );
            }}
            value={convertValuesToSupportedFormat(value).toString()}
            placeholder="Enter value or select token"
          />
        </>
      );
    }
    else if (detectTypeNoCodeForRender(value) === "date" || detectTypeNoCodeForRender(value) === "datetime-local") {
      return (
        <>
          <DataListInput
            label=""
            value={convertValuesToSupportedFormat(value).toString()}
            placeholder={`Enter ${detectTypeNoCodeForRender(value)}  or select token`}
            items={getDataListItems("", detectTypeNoCodeForRender(value))}
            setValue={setValue}
            filters={[(items) => items]}
            onChange={(e) => {
              let updatedValue = e.target.value;
              if (updateValue === "") {
                updatedValue = NOCODE_DEFAULT_VALUE[detectTypeNoCode(value)];
              }
              updateFieldValue(
                detectTypeNoCode(value),
                parentKey,
                updatedValue
              );
            }}
            onSelect={(e) => {
              updateFieldValue(detectTypeNoCode(value), parentKey, e.value);
            }}
          />
        </>
      );
    } 
    else {
      return (
        <>
          <DataListInput
            label=""
            value={convertValuesToSupportedFormat(value).toString()}
            placeholder="Enter value or select token"
            items={getDataListItems(value)}
            setValue={setValue}
            filters={[(items) => items]}
            onChange={(e) => {
              let updatedValue = e.target.value;
              if (updateValue === "") {
                updatedValue = NOCODE_DEFAULT_VALUE[detectTypeNoCode(value)];
              }
              updateFieldValue(
                detectTypeNoCode(value),
                parentKey,
                updatedValue
              );
            }}
            onSelect={(e) => {
              updateFieldValue(detectTypeNoCode(value), parentKey, e.value);
            }}
          />
        </>
      );
    }
  };

  return (
    <div
      className={`json-editor-${from} ${
        !isParentArray(parentKey) && parentKey !== ""
          ? "apply-margin"
          : "remove-margin"
      }`}
    >
      <div className="json-editor-container">
        {jsonObj && <>
        {Array.isArray(jsonObj) ? (
          <>
            <JSONAccordion
              id={parentKey ? parentKey + "##" : ""}
              defaultState={getDefaultState(parentKey, jsonObj)}
              type={isParentArray(parentKey) ? "value-obj" : "key-value"}
              helpLinesNeeded={false}
              title={
                isParentArray(parentKey) ? (
                  <>
                    <div className="object-notation-container">
                      <div>
                        {Array.isArray(jsonObj) ? "[" : "{"}
                        <div className="node-ellipsis">...</div>
                        {Array.isArray(jsonObj) ? "]" : "}"}{" "}
                      </div>
                      <span className="object-items-text">
                        {renderKeyLength(detectTypeNoCode(jsonObj), jsonObj)}{" "}
                        Items
                      </span>
                    </div>

                    <button
                      className="key-delete-array"
                      onClick={(e) => handleRemove(0, parentKey, 0)}
                    >
                      <RxCross2 />
                    </button>
                  </>
                ) : (
                  ""
                )
              }
            >
              {jsonObj.map((key, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="json-editor-row"
                      style={{ background: "#fcfcfc" }}
                    >
                      <div className="array-key">
                        {"["}
                        {index}
                        {"]"}
                      </div>
                      <div className="value-editor-container">
                        {typeof jsonObj[index] === "object" &&
                        jsonObj[index] !== null ? (
                          <NectedJSONEditor
                            parentKey={`${
                              parentKey ? parentKey + "##" : ""
                            }${index}`}
                            parentJSON={jsonObj[index]}
                            originalPayload={originalPayload}
                            nectedSuggestions={nectedSuggestions}
                            onChange={(newJson) => {
                              onChange(newJson);
                            }}
                            from="child"
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                          />
                        ) : (
                          <div className="array-value">
                            {renderFields(
                              jsonObj[index],
                              updateFieldValue,
                              parentKey.split("##").concat(index),
                              nectedSuggestions
                            )}
                            <span className="data-type-pill">
                              {detectTypeNoCode(jsonObj[index])}
                            </span>
                            <button
                              className="key-delete"
                              onClick={(e) => {
                                handleRemove(
                                  index,
                                  parentKey
                                    .split("##")
                                    .concat(index)
                                    .join("##"),
                                  index
                                );
                              }}
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <AddNewData
                editorMeta={editorMeta}
                id={parentKey}
                payload={jsonObj}
                onClick={handleAddNewValue}
                buttonName={
                  Array.isArray(jsonObj) ? `Add List Item` : `Add JSON Key`
                }
              />
            </JSONAccordion>
          </>
        ) : (
          <>
            {Object.keys(jsonObj).map((key, index) => (
              <div
                key={index}
                className={`json-editor-row ${
                  typeof jsonObj[key] === "object" && jsonObj[key] !== null
                    ? "value-obj"
                    : "key-value"
                }`}
              >
                <JSONAccordion
                  key={index}
                  id={parentKey}
                  defaultState={getDefaultState(parentKey, jsonObj[key])}
                  helpLinesNeeded={isParentArray(parentKey)}
                  type={
                    typeof jsonObj[key] === "object" && jsonObj[key] !== null
                      ? "value-obj"
                      : "key-value"
                  }
                  title={
                    <div className="key-editor-container">
                      <div className="container">
                        <DataListInput
                          label=""
                          value={convertValuesToSupportedFormat(key)}
                          placeholder="Enter or select key name"
                          items={nectedSuggestions.filter(
                            (i) =>
                              i.meta === "string" &&
                              i.executedValue &&
                              !i.executedValue.includes('"')
                          )}
                          setValue={setValue}
                          onChange={(e) => {
                            const updatedKey = e.target.value;
                            if (jsonObj.hasOwnProperty(updatedKey)) {
                              alert(
                                `Cannot update as keyname : '${updatedKey}' is already present.`
                              );
                              return;
                            } else {
                              handleUpdateKeyName(key, updatedKey, parentKey);
                            }
                          }}
                          onSelect={(e) => {
                            const updatedKey = e.value.replaceAll('"', "");
                            if (jsonObj.hasOwnProperty(updatedKey)) {
                              alert(
                                `Cannot update as keyname : '${updatedKey}' is already present.`
                              );
                              return;
                            } else {
                              handleUpdateKeyName(key, updatedKey, parentKey);
                            }
                          }}
                        />
                        {typeof jsonObj[key] === "object" &&
                          jsonObj[key] !== null && (
                            <div className="object-notation-container">
                              <div>
                                {Array.isArray(jsonObj[key]) ? "[" : "{"}
                                <div className="node-ellipsis">...</div>
                                {Array.isArray(jsonObj[key]) ? "]" : "}"}{" "}
                              </div>
                              <span className="object-items-text">
                                {renderKeyLength(
                                  detectTypeNoCode(jsonObj[key]),
                                  jsonObj[key]
                                )}{" "}
                                Items
                              </span>
                            </div>
                          )}
                      </div>
                      {typeof jsonObj[key] === "object" &&
                        jsonObj[key] !== null && (
                          <div className="keys-details-container">
                            <div className="total-keys">
                              <span className="data-type-pill">
                                {detectTypeNoCode(jsonObj[key])}
                              </span>
                            </div>
                            <button
                              className="key-delete"
                              onClick={(e) => {
                                handleRemove(key, parentKey, index);
                              }}
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        )}
                    </div>
                  }
                >
                  <div className="value-editor-container">
                    {typeof jsonObj[key] === "object" &&
                    jsonObj[key] !== null ? (
                      <NectedJSONEditor
                        parentKey={`${parentKey ? parentKey + "##" : ""}${key}`}
                        parentJSON={jsonObj[key]}
                        originalPayload={originalPayload}
                        nectedSuggestions={nectedSuggestions}
                        onChange={(newJson) => {
                          onChange(newJson);
                        }}
                        from="child"
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                      />
                    ) : (
                      <>
                        {renderFields(
                          jsonObj[key],
                          updateFieldValue,
                          parentKey.split("##").concat(key),
                          nectedSuggestions
                        )}
                        <span className="data-type-pill">
                          {detectTypeNoCode(jsonObj[key])}
                        </span>
                        <button
                          className="key-delete"
                          onClick={(e) => {
                            handleRemove(key, parentKey, index);
                          }}
                        >
                          <RxCross2 />
                        </button>
                      </>
                    )}
                  </div>
                </JSONAccordion>
              </div>
            ))}
            <AddNewData
              editorMeta={editorMeta}
              id={parentKey}
              payload={jsonObj}
              onClick={handleAddNewValue}
              buttonName={
                Array.isArray(jsonObj) ? `Add List Key` : `New JSON Item`
              }
            />
          </>
        )}
        </>}
      </div>
    </div>
  );
};

export default NectedJSONEditor;
