/* eslint-disable jsx-a11y/alt-text */
import * as DropdownMenu  from "@radix-ui/react-dropdown-menu";
import { NoCodeDataTypes } from "../../../utils/utils";

export const AddNewData = ({ onClick, buttonName, id, payload , editorMeta}) => {
    const allowedType = editorMeta?.restrictDataTypeInList === "numeric" ? "number" : editorMeta?.restrictDataTypeInList;
    const UpdatedNoCodeDataTypes = allowedType ? NoCodeDataTypes.filter((i) => i.value === allowedType) : NoCodeDataTypes; 
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="add-new-key" data-parentKey={id}>
            <span><img style={{ maxWidth : '80%'}} src="/konark/website/icons/plus.svg"/> </span>
            <span className="add-button-title"> {Array.isArray(payload) ? `Add Item` : (payload !== null ? `Add Key` : 0) }
            <span className="add-button-parentKey"> to {id !== "" ? id.split("##")[id.split("##").length - 1] : "root"} {Array.isArray(payload) ? `[]` : (payload !== null ? `{}` : null) }</span></span>
            </button>
        </DropdownMenu.Trigger>
  
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
            {UpdatedNoCodeDataTypes.map((item, index) => {
              return (
                <DropdownMenu.Item
                  key={index}
                  className="DropdownMenuItem"
                  onClick={(e) => onClick(item.value, id, payload)}
                >
                  {item.name}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  };
  
  export default AddNewData;